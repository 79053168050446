import React from "react";
import TextTransition, { presets } from "react-text-transition";

import "./style.css"

import ProfilePicture from "../../imgs/bjr.jpeg";
import CMoi from "../../imgs/cmoi.png";

import Vegan from "../../imgs/001-vegan.png";
import Digestion from "../../imgs/002-digestion.png";
import Weight from "../../imgs/003-weight.png";
import Scale from "../../imgs/004-scale.png";
import Brain from "../../imgs/005-brain.png";
import Loupe from "../../imgs/006-loupe.png";


function Home() {
    const sentences = [
      'de mieux manger', 
      'de perdre du poids', 
      'de faire du sport', 
      'de vous sentir mieux',
      'd\'être plus en forme',
      'd\'être moins stressé·e',
      'd\'être de meilleure humeur',
    ]

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
      const intervalId = setInterval(() =>
        setIndex(index => index + 1),
        3000
      );
      return () => clearTimeout(intervalId);
    }, []);

    return <div className="home-container">

      { /* Navbar */ }
      <section id="home" className="section-container navbar">
        <div className="deco tilted-background-navbar"></div>
        <div className="section-content">
          <div className="navbar-content">
            <div className="navbar-brand">
              <span>Sophie Francoulon</span>
            </div>
            <div className="navbar-menu">
              <ul>
                <li><a href="#services">Services</a></li>
                <li><a href="#tarifs">Tarifs</a></li>
                <li><a href="#contact">Contact</a></li>
                <li>
                <a target="_blank" href="https://www.doctolib.fr/dieteticien/montpellier/sophie-francoulon" className="call-to-action" rel="noreferrer">
                Prendre rendez-vous →
                </a>
                </li>
              </ul>
            </div>
          </div>    
        </div>        
      </section>

      { /* Accueil */ }


      <section className="pourquoi section-container">
        <div className="section-content">
          <div className="header-catch">
            <div className="catch">Vous avez envie <TextTransition springConfig={presets.gentle}>
              {sentences[index % sentences.length] + " ?"}
            </TextTransition></div>
            <div className="subcatch">
              Je vous accompagne et vous renseigne sur les solutions possibles pour vous sentir mieux et optimiser votre santé.
            </div>
            <a target="_blank" href="https://www.doctolib.fr/dieteticien/montpellier/sophie-francoulon" className="call-to-action" rel="noreferrer">
              Prendre rendez-vous →
            </a>
          </div>
          <div className="profile-picture-container">
            <img className="pdp" src={ProfilePicture} alt="sophie"/>
            <img className="cmoi" src={CMoi} alt="sophie"/>
          </div>
        </div>
      </section>

      { /* Dans quel but ? */ }

      <section id="services" className="section-container services">
        <div className="deco tilted-background-what"></div>
        <div className="section-content">
          <div className="services-lines">
            <div className="service-bloc a">
              <div className="service-title">Digestion</div>
              <div className="service-image">
                <img src={Digestion} alt="digestion"/>
              </div>
              <div className="service-description">
                Améliorez votre confort digestif.
              </div>
            </div>
            <div className="service-bloc b">
              <div className="service-title">Perte de poids</div>
              <div className="service-image">
                <img src={Weight} alt="weight"/>
              </div>
              <div className="service-description">
                Perdre du poids en respectant votre corps et vos envies, sans privations ni frustrations.
              </div>
            </div>
            <div className="service-bloc c">
              <div className="service-title">Végétarisme</div>
              <div className="service-image">
                <img src={Vegan} alt="vegan"/>
              </div>
              <div className="service-description">
                Je vous accompagne pour votre transition végé ou pour faire un point sur votre régime actuel.
              </div>
            </div>
          </div>
          <div className="services-lines">
            <div className="service-bloc d">
              <div className="service-title">Rééquilibrage alimentaire</div>
              <div className="service-image">
                <img src={Scale} alt="scale"/>
              </div>
              <div className="service-description">
                On rééquilibre vos repas que ce soit pour votre santé, votre poids ou vos performances.
              </div>
            </div>
            <div className="service-bloc e">
              <div className="service-title">Psychonutrition</div>
              <div className="service-image">
                <img src={Brain} alt="brain"/>
              </div>
              <div className="service-description">
                Je prends en compte l’aspect psychologique de votre rapport à l’alimentation pour retrouver de
                l’apaisement face à la nourriture.
              </div>
            </div>
            <div className="service-bloc f">
              <div className="service-title">Micronutrition</div>
              <div className="service-image">
                <img src={Loupe} alt="loupe"/>
              </div>
              <div className="service-description">
                On zoom sur les micronutriments pour répondre à des objectifs spécifiques (stress, sommeil,
                concentration).
              </div>
            </div>
          </div>
        </div>        
      </section>

      { /* Tarifs */ }

      <section id="tarifs" className="prices section-container">
      <div className="deco tilted-background-prices"></div>
        <div className="section-content tarifs-bis">
          <div className="prices-header">Tarifs</div>
          <div className="prices-sub-header hidden">À la carte</div>
          <div className="prices-container-flex">            
            <Price 
              name="Consultation de bilan" 
              doctolib
              price="60" 
              flexible
              stripe="https://buy.stripe.com/"/>            
            <Price 
              name="Consulation de suivi" 
              price="45" 
		doctolib
              flexible
              stripe="https://buy.stripe.com/"/> 
          </div>
          <div className="prices-sub-header hidden">Packs</div>
          <div className="prices-container hidden">            
            <Price 
              name="Découverte" 
              price="189" 
              recurrence="sans engagement"
              services={
                [
                  ["Bilan nutritionnel", true],
                  ["3 Suivis", true ],
                  ["Compte rendu avec objectifs", true],
                  ["Création de menus", true],
                  ["Idées de recettes", false],
                  ["Analyse des placards", false],
                  ["Disponibilité 5 jours sur 7", false],
                ]
              }
              stripe="https://buy.stripe.com/3cs3e79rK8f68fecMQ"/>
            <Price 
              name="Exploration" 
              price="320" 
              recurrence="sans engagement"
              services={
                [
                  ["Bilan nutritionnel", true],
                  ["6 Suivis", true ],
                  ["Compte rendu avec objectifs", true],
                  ["Création de menus", true],
                  ["Idées de recettes", true, true],
                  ["Analyse des placards", false],
                  ["Disponibilité 5 jours sur 7", false],
                ]
              }
              stripe="https://buy.stripe.com/5kAeWP7jCfHy676cMP"/>
            <Price 
              name="Expédition" 
              price="165" 
              recurrence="par mois pendant 6 mois"
              services={
                [
                  ["Bilan nutritionnel", true],
                  ["11 Suivis", true ],
                  ["Compte rendu avec objectifs", true],
                  ["Création de menus", true],
                  ["Idées de recettes", true],
                  ["Analyse des placards", true, true],
                  ["Disponibilité 5 jours sur 7", true, true],
                ]
              }
              stripe="https://buy.stripe.com/eVadSL9rK9ja7bafZ0"/>
          </div>
        </div>
      </section>

      { /* Footer */ }

      <section id="contact" className="footer section-container">
        <div className="section-content">
        <div className="footer-contact">
            <div><b>Contact</b></div>
            <a href="tel:+33607080910">📞 +33 6 56 74 09 80</a>
            <a href="mailto:sophie@francoulon.diet">📩 Par email</a>
            <a href="https://instagram.com/sophiebonjour_">📸 Instagram</a>        
            <a href="https://twitch.tv/sophiebonjour">📹 Twitch</a>   
            <div className="copyright">
              © <a href="https://twitter.com/jussetain" target="_blank" rel="noreferrer">jussetain</a>, 2023 - Tous droits réservés
            </div>        
          </div>
          <div className="footer-address">
            <div><b>Cabinet</b></div>
            <div><strong>Médi'Caux Santé</strong></div>
            <div>300 Rue Auguste Badin</div>
            <div>76360 Barentin</div>   
          </div>
        </div>
      </section>      
  </div>  
}

function Price(props) {
  return <div className="price-bloc">
    <div className="price-bloc-header">
      <div className="price-name">{props.name}</div>
      <div className="price-value">{props.price}€</div>
      <div className="price-alt-value">{props.alt_price}</div>
    
      {
        props.recurrence ? <div className="price-recc">
          {props.recurrence}
        </div> : <div className="price-recc">

        </div>
      }
      { !props.flexible ? <div className="price-services-container">
        {
          props.services && props.services.map(s => {
            return <div className="price-service">
                <span>{(s[1] ? '✅ ' : '❌ ')}</span>
                <span className={`${s[2] ? 'service-new' : ''} ${!s[1] ? 'service-no' : ''}`}>{s[0]}</span>              
              </div>;
          })
        }
      </div> : ''}
      { props.stripe ? 
      <div className={`pack-button${props.flexible ? "-flex" : ''}`}>
        <a target="_blank" href={props.doctolib ? 'https://www.doctolib.fr/dieteticien/montpellier/sophie-francoulon' : props.stripe} rel="noreferrer">
          {
            props.doctolib ? "Prendre rendez-vous" : "Commander"
          }
        </a>
      </div>
       : ''}
    </div>
 </div>
}

export default Home;
